import { trigger, transition, style, animate, state, sequence } from '@angular/animations';
import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  OnDestroy,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TutorialLevels } from './measurement-transfer-tutorial/measurement-transfer-tutorial.component';
import { DeviceType } from 'app/app-store/devices-store/devices.model';
import {
  Bf720State,
  Bm54State,
  Bm57State,
  Bm64State,
  Po60State,
} from 'store/devices-store/devices.model';
import * as dayjs from 'dayjs';
import { environment } from 'environments/environment';

type TileAnimationStatus = 'left' | 'center' | 'right';

@Component({
  selector: 'pro-measurement-transfer',
  templateUrl: './measurement-transfer.component.html',
  styleUrls: ['./measurement-transfer.component.scss'],
  animations: [
    trigger('ctaAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms 300ms ease', style({ opacity: 1 })),
      ]),
    ]),
    trigger('tileAnimation', [
      state('left', style({ transform: 'translateX(-120%)' })),
      state('center', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(120%)' })),
      transition('left => center', [
        style({ transform: 'translateX(-120%)' }),
        animate('600ms {{inDelay}}ms ease-in', style({ transform: 'translateX(0)' })),
      ]),
      transition('center => right', [
        style({ transform: 'translateX(0)' }),
        animate('600ms {{outDelay}}ms ease', style({ transform: 'translateX(120%)' })),
      ]),
      transition('center => left', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('600ms ease-out', style({ opacity: 0, transform: 'translateX(0)' })),
      ]),
    ]),
    trigger('closeBtnAnimation', [
      transition(':enter', [
        style({ opacity: 0, width: 0 }),
        sequence([
          animate('200ms', style({ width: '24px' })),
          animate('100ms', style({ opacity: 1 })),
        ]),
      ]),
      transition(':leave', [
        style({ opacity: 1, width: '24px' }),
        sequence([animate('100ms', style({ opacity: 0 })), animate('200ms', style({ width: 0 }))]),
      ]),
    ]),
  ],
})
export class MeasurementTransferComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  private IS_DISABELED = false;

  @ViewChild('manualMeasurementCta', { read: ElementRef }) manualMeasurementCta: ElementRef;

  @Input() set devices(value: (Bm54State | Bm57State | Bm64State | Bf720State | Po60State)[]) {
    // links to the device-pages are created based on the device-infos
    this.devicesList = value.map((device) => {
      const routerLinkNotConnected =
        device.type === DeviceType.BloodPressureMonitor
          ? `/app/devices/select`
          : `/app/devices/${device.name}`;

      return {
        name: device.name,
        type: device.type,
        routerLink: device.address
          ? `/app/devices/connection/${device.name}`
          : routerLinkNotConnected,
        queryParams:
          device.type === DeviceType.BloodPressureMonitor
            ? { type: 'BloodPressureGoal', manual: device.address !== null }
            : { type: undefined, manual: device.address !== null },
      };
    });
  }

  @Input() set isDisabled(value: boolean) {
    this.IS_DISABELED = value;

    if (value === true) {
      this.displayCta = false;
      this.tutorialVisible = false;
      this.tutorialActive = false;
      this.activeLevel = null;
    }

    // reset cta- and tutorial-trigger- position if the button is currently hidden and the app is not disabled
    if (!(value || this.displayCta)) {
      this.setCtaVisibility();
    }
  }

  @Input() measurementTransferTutorialShownAt: string;

  @Output() setMeasurementTutorialTimestamp: EventEmitter<string> = new EventEmitter();

  devicesList: {
    name: string;
    type: DeviceType;
    routerLink: string;
    queryParams: { type: string; manual: boolean };
  }[];

  displayCta = false;
  activeLevel: number = null;
  levelOneStatus: TileAnimationStatus = 'left';
  levelTwoStatus: TileAnimationStatus = 'left';
  // Tutorial
  tutorialVisible = false;
  tutorialActive = false;
  tutorialLevel: TutorialLevels;
  tutorialAutoplay = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    // Waits for navigation-end
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        this.setCtaVisibility();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  /**
   * Displays the cta if snapshot data is present.
   * Adds/Removes css-class to the html-element to adjust content styles.
   */
  setCtaVisibility() {
    this.tutorialVisible = false;
    this.tutorialActive = false;
    const htmlElement = document.querySelector('html');

    // Finds last firstChild in current snapshot
    let snapshot = this.activatedRoute?.snapshot;

    while (snapshot?.firstChild) {
      snapshot = snapshot.firstChild;
    }

    this.displayCta =
      this.IS_DISABELED === false && snapshot?.data?.manualMeasurementCta ? true : false;

    this.displayCta
      ? this.renderer.addClass(htmlElement, 'manual-measurement-cta-visible')
      : this.renderer.removeClass(htmlElement, 'manual-measurement-cta-visible');
  }

  /**
   * Displays/Hides the first selection level
   */
  toggleSelection() {
    if (this.tutorialActive === true) {
      if (this.tutorialLevel === TutorialLevels.ctaInfo) {
        this.tutorialLevel = TutorialLevels.prepareTileInfo;
        // Waits for tutorial-animation to finish
        setTimeout(() => {
          this.levelOneStatus = 'center';
          this.activeLevel = 1;
          this.setItemsPosition();
          this.tutorialLevel = TutorialLevels.bluetoothInfo;
        }, 450);
      }
      return;
    }

    if (this.activeLevel === null) {
      this.levelOneStatus = 'center';
      this.activeLevel = 1;
    } else {
      this.levelOneStatus = 'left';
      this.levelTwoStatus = 'left';
      this.activeLevel = null;
    }

    this.setItemsPosition();
  }

  /**
   * Displays devices tiles
   */
  openDevices() {
    if (this.tutorialActive === true) {
      return;
    }

    this.levelOneStatus = 'right';
    this.levelTwoStatus = 'center';
    this.activeLevel = 2;
    this.setItemsPosition();
  }

  /**
   * Navigates to manual measurement-form
   * Returns if tutorial is active
   */
  navigateToManualMeasurementForm() {
    if (this.tutorialActive === true) {
      return;
    }
    this.toggleSelection();
    this.router.navigate(['/app/manual-measurement']);
  }

  /**
   * Calculates tiles position, based on the current button position
   */
  setItemsPosition() {
    let currentTopPosition = this.manualMeasurementCta?.nativeElement?.offsetTop - 16;
    const buttons: HTMLElement[] = this.elRef.nativeElement.querySelectorAll(
      `ion-item.level-${this.activeLevel}`
    );

    Array.from(buttons)
      .reverse()
      .map((button: HTMLElement) => {
        currentTopPosition = currentTopPosition - button.offsetHeight;
        this.renderer.setStyle(button, 'top', `${currentTopPosition}px`);
        // Adds top-margin to previous element
        currentTopPosition = currentTopPosition - 8;
      });
  }

  // Tutorial
  /**
   * Inits tutorial-trigger after cta was animated
   * @param triggerEvent animation-event done
   */
  initTutorial(triggerEvent: any) {
    if (triggerEvent?.fromState === 'void' && triggerEvent?.toState === null) {
      this.tutorialAutoplay = false;
      this.tutorialActive = false;
      this.tutorialLevel = undefined;

      // Checks first tutorial appearance
      if (this.measurementTransferTutorialShownAt === null) {
        this.setMeasurementTutorialTimestamp.emit(dayjs().format('YYYY-MM-DD'));
        // prevent autoplay for e2e-environment
        this.tutorialAutoplay = environment?.e2e ? false : true;
        this.tutorialVisible = true;
      } else {
        // Hides trigger if it was visible for 5 days already
        this.tutorialVisible = dayjs(this.measurementTransferTutorialShownAt).isBefore(
          dayjs().subtract(5, 'day')
        )
          ? false
          : true;
      }
    }
  }

  setTutorialLevel(level: number) {
    // Closes button(s)
    if (
      (this.tutorialActive === false && level !== null) ||
      (this.tutorialActive === true && level === TutorialLevels.cancelTutorial) ||
      (this.tutorialActive === true && level === TutorialLevels.finishTutorial)
    ) {
      this.levelOneStatus = 'left';
      this.levelTwoStatus = 'left';
      this.activeLevel = null;
    }

    if (this.tutorialActive === true && level === TutorialLevels.finishTutorial) {
      // Sets past date to avoid displaying trigger again
      this.setMeasurementTutorialTimestamp.emit(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
      this.tutorialActive = false;
      this.tutorialVisible = false;
      return;
    }

    this.tutorialLevel = level;
    this.tutorialActive = level === null ? false : true;
  }
}
