import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { Observable, from, throwError, EMPTY } from 'rxjs';
import { first, map, switchMap, catchError } from 'rxjs/operators';
import { Auth } from 'app/models';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storage: StorageFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.storage.get('auth')).pipe(
      first(),
      switchMap((auth: Auth) => {
        let authRequest = request;

        if (auth) {
          authRequest = request.clone({
            setHeaders: {
              ['authorization']: auth.jwtToken,
              ['accept']: 'application/json, text/plain',
            },
          });
        } else {
          return EMPTY;
        }

        return next.handle(authRequest).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error);
          }),
        );
      }),
    );
  }
}
